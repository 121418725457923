import validate from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/node_modules/nuxt/dist/pages/runtime/validate.js";
import maintenance_45global from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/middleware/maintenance.global.ts";
import manifest_45route_45rule from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  maintenance_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  admin: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/middleware/admin.ts"),
  "agreed-responsable-terms": () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/middleware/agreed-responsable-terms.ts"),
  authenticated: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/middleware/authenticated.ts"),
  guest: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/middleware/guest.ts"),
  "old-search-redirections": () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/middleware/old-search-redirections.ts")
}