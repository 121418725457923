import { default as accessibiliteuODJSdVLQEMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/accessibilite.vue?macro=true";
import { default as _91slug_93d2i713XMrCMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/activites/[slug].vue?macro=true";
import { default as editdUqbTwebM7Meta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/contenus/activites/[id]/edit.vue?macro=true";
import { default as indexFo0oXpEzRjMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/contenus/activites/[id]/index.vue?macro=true";
import { default as addXI1IZd7CGpMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/contenus/activites/add.vue?macro=true";
import { default as indexj2jXCiCPybMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/contenus/activites/index.vue?macro=true";
import { default as editYIJpxyENBBMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/contenus/domaines/[id]/edit.vue?macro=true";
import { default as addmzI0uK9tNRMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/contenus/domaines/add.vue?macro=true";
import { default as index0MdTELNN0yMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/contenus/domaines/index.vue?macro=true";
import { default as editpjWiOlNS3RMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/contenus/modeles-mission/[id]/edit.vue?macro=true";
import { default as addg4NzeR3vgjMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/contenus/modeles-mission/add.vue?macro=true";
import { default as indexIjnO5TGwyWMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/contenus/modeles-mission/index.vue?macro=true";
import { default as postsuaf6WDt8VOMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/contenus/posts.vue?macro=true";
import { default as edityftKah94CaMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/contenus/reseaux/[id]/edit.vue?macro=true";
import { default as indexcpMHsFDLO0Meta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/contenus/reseaux/[id]/index.vue?macro=true";
import { default as add9A10lmvnNCMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/contenus/reseaux/add.vue?macro=true";
import { default as indexZGngJY1ru0Meta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/contenus/reseaux/index.vue?macro=true";
import { default as editN95l5gh3PaMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/contenus/ressources/[id]/edit.vue?macro=true";
import { default as addd8JPRusFUJMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/contenus/ressources/add.vue?macro=true";
import { default as indexWg4rmF9RHGMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/contenus/ressources/index.vue?macro=true";
import { default as edit2Oi2sPidGDMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/contenus/territoires/[id]/edit.vue?macro=true";
import { default as indexJZ7XVs7kHyMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/contenus/territoires/[id]/index.vue?macro=true";
import { default as addjeq9ToOfxTMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/contenus/territoires/add.vue?macro=true";
import { default as indext55XrfPjUvMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/contenus/territoires/index.vue?macro=true";
import { default as editjMmVumNsqjMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/contenus/testimonials/[id]/edit.vue?macro=true";
import { default as indexNCyKQ1VdHcMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/contenus/testimonials/index.vue?macro=true";
import { default as indexOARPo31EkbMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/index.vue?macro=true";
import { default as benevoles_45informationsmp79lbTf6zMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/missions/[id]/benevoles-informations.vue?macro=true";
import { default as benevoleswwkBeRLVPSMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/missions/[id]/benevoles.vue?macro=true";
import { default as datesjb7WvJvVg1Meta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/missions/[id]/dates.vue?macro=true";
import { default as indexgVex4zq4qoMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/missions/[id]/index.vue?macro=true";
import { default as informationsjIYe1XCOQVMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/missions/[id]/informations.vue?macro=true";
import { default as lieuxy8oDyVt6cEMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/missions/[id]/lieux.vue?macro=true";
import { default as responsables7IlVyA1RD9Meta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/missions/[id]/responsables.vue?macro=true";
import { default as title9r4p0kii6SMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/missions/[id]/title.vue?macro=true";
import { default as trouver_45des_45benevoles3dFUGbZsCcMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/missions/[id]/trouver-des-benevoles.vue?macro=true";
import { default as visuelnShcivFjboMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/missions/[id]/visuel.vue?macro=true";
import { default as indexAYyaUMjfAfMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/missions/index.vue?macro=true";
import { default as notesX4bn1TqcrBMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/notes.vue?macro=true";
import { default as edit0NXocZgtxgMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/organisations/[id]/edit.vue?macro=true";
import { default as indexR14TWXGQlhMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/organisations/[id]/index.vue?macro=true";
import { default as addJkAlCb8NeBMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/organisations/[id]/missions/add.vue?macro=true";
import { default as indexVWyGXj1vcLMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/organisations/index.vue?macro=true";
import { default as invitationsIGHuTfsL8KMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/other/invitations.vue?macro=true";
import { default as logsU38qtM0WMMMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/other/logs.vue?macro=true";
import { default as indexXqZrIseBWBMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/participations/index.vue?macro=true";
import { default as ressourcesGUW9Tq6LaBMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/ressources.vue?macro=true";
import { default as emailsTIlFSVvEpwMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/settings/emails.vue?macro=true";
import { default as messagesVnCqOEXbJSMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/settings/messages.vue?macro=true";
import { default as editxWObBvY7jZMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/settings/rules/[id]/edit.vue?macro=true";
import { default as pending_45itemspzj4aPLzBcMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/settings/rules/[id]/pending-items.vue?macro=true";
import { default as addhSiHdKO4ZjMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/settings/rules/add.vue?macro=true";
import { default as indexy8ru9NAPjiMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/settings/rules/index.vue?macro=true";
import { default as trafic_45entrantwnxnFcugagMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/statistics/api-engagement/trafic-entrant.vue?macro=true";
import { default as trafic_45sortantsabzBXUP8aMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/statistics/api-engagement/trafic-sortant.vue?macro=true";
import { default as conversionsOQijExjPmPMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/statistics/conversions.vue?macro=true";
import { default as missions_45a_45validera4j2K4dDWZMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/statistics/departements/missions-a-valider.vue?macro=true";
import { default as missions_45perimeesKmDb3iXrUbMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/statistics/departements/missions-perimees.vue?macro=true";
import { default as organisations_45a_45validervMJlZt9LtyMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/statistics/departements/organisations-a-valider.vue?macro=true";
import { default as indexjVICKTVQCvMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/statistics/index.vue?macro=true";
import { default as indicateurs_45clesBK5XSS90O7Meta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/statistics/indicateurs-cles.vue?macro=true";
import { default as missionszYlYkTizmRMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/statistics/missions.vue?macro=true";
import { default as indexAvfl5wyr3eMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/statistics/organisations/index.vue?macro=true";
import { default as missions_45perimeesmstDB2O3GxMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/statistics/organisations/missions-perimees.vue?macro=true";
import { default as participations_45a_45validerrMgH5xvKNQMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/statistics/organisations/participations-a-valider.vue?macro=true";
import { default as participations_45refusees_45annuleesDEbGFImy3xMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/statistics/organisations/participations-refusees-annulees.vue?macro=true";
import { default as participations9JTh4EY8CeMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/statistics/participations.vue?macro=true";
import { default as placesaeArWjmSAGMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/statistics/places.vue?macro=true";
import { default as moderationsavF04747TuMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/statistics/suivis/moderations.vue?macro=true";
import { default as utilisateurs5Ug1uzEgBXMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/statistics/utilisateurs.vue?macro=true";
import { default as edit0cRrT8vKoyMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/taxonomies/[slug]/[id]/edit.vue?macro=true";
import { default as addwGfFowiQRvMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/taxonomies/[slug]/add.vue?macro=true";
import { default as indexEz5I6a1yBKMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/taxonomies/[slug]/index.vue?macro=true";
import { default as temoignages74t1be0uhCMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/temoignages.vue?macro=true";
import { default as editEEWTeE7Jm7Meta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/utilisateurs/[id]/edit.vue?macro=true";
import { default as indexkTMGcuyJsmMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/utilisateurs/[id]/index.vue?macro=true";
import { default as indexeEyA5b66YLMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/utilisateurs/index.vue?macro=true";
import { default as benevolesU5BjCscEOzMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/charte-bon-fonctionnement/benevoles.vue?macro=true";
import { default as indexBu0zNiGPbXMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/charte-bon-fonctionnement/index.vue?macro=true";
import { default as responsablesDcSco09hzVMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/charte-bon-fonctionnement/responsables.vue?macro=true";
import { default as charte_45reserve_45civiqueNGQeQxTlxRMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/charte-reserve-civique.vue?macro=true";
import { default as conditions_45generales_45d_45utilisationZClm8G4wiHMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/conditions-generales-d-utilisation.vue?macro=true";
import { default as indexqdTcg4klblMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/dashboard/index.vue?macro=true";
import { default as _91slug_93y2XOOQj3L5Meta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/departements/[slug].vue?macro=true";
import { default as _91slug_93rMj9rSntWPMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/domaines-action/[slug].vue?macro=true";
import { default as en_45ce_45momentJ0UyEcoX1LMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/en-ce-moment.vue?macro=true";
import { default as indexkjzh5ri0ibMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/index.vue?macro=true";
import { default as indexlEkuSNcupyMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/inscription/benevole/index.vue?macro=true";
import { default as competencesgVJh930GW1Meta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/inscription/benevole/step/competences.vue?macro=true";
import { default as disponibilitescYqneXplOaMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/inscription/benevole/step/disponibilites.vue?macro=true";
import { default as preferences7pwzDDqAGvMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/inscription/benevole/step/preferences.vue?macro=true";
import { default as profilesV8jRRYoiDMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/inscription/benevole/step/profile.vue?macro=true";
import { default as index6TNqFOkSXMMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/inscription/index.vue?macro=true";
import { default as indexpmmAC3hdNbMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/inscription/responsable/index.vue?macro=true";
import { default as charte_45bon_45fonctionnementZqW2bYf0DaMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/inscription/responsable/step/charte-bon-fonctionnement.vue?macro=true";
import { default as collectivite_45confirmationPxOM6VUAtOMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/inscription/responsable/step/collectivite-confirmation.vue?macro=true";
import { default as collectiviteDx0ZCaD77kMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/inscription/responsable/step/collectivite.vue?macro=true";
import { default as organisation_45confirmationYgenJxAlG2Meta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/inscription/responsable/step/organisation-confirmation.vue?macro=true";
import { default as organisation_45details65IZuCc0H0Meta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/inscription/responsable/step/organisation-details.vue?macro=true";
import { default as organisation_45images1gCbx4SmAOMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/inscription/responsable/step/organisation-images.vue?macro=true";
import { default as organisationZe0wpyGxefMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/inscription/responsable/step/organisation.vue?macro=true";
import { default as profileSWF2kiBtTMMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/inscription/responsable/step/profile.vue?macro=true";
import { default as _91hash_93kL2u7q4DtJMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/invitations/[hash].vue?macro=true";
import { default as loginwbfs2Pdm9nMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/login.vue?macro=true";
import { default as admintaUOxiDNmcMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/maintenance/admin.vue?macro=true";
import { default as index4HL3FTpFJyMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/maintenance/index.vue?macro=true";
import { default as mentions_45legales4Bcc5wHiwBMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/mentions-legales.vue?macro=true";
import { default as _91id_93vKIe5pA57PMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/messages/[id].vue?macro=true";
import { default as indexAD1IwiyI22Meta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/messages/index.vue?macro=true";
import { default as addWn6VSjOHt2Meta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/messages/modeles/add.vue?macro=true";
import { default as indexMXPvuVVi1QMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/messages/modeles/index.vue?macro=true";
import { default as _91slug_93fY2ZztLD7xMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/missions-benevolat/[id]/[slug].vue?macro=true";
import { default as indexhuEsKcoqPsMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/missions-benevolat/[id]/index.vue?macro=true";
import { default as indexHl9aG2fXgBMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/missions-benevolat/index.vue?macro=true";
import { default as notificationsw0T8Sinz7NMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/notifications.vue?macro=true";
import { default as _91slug_93c5vvjP75TqMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/organisations/[slug].vue?macro=true";
import { default as indexhMNhkeWBs7Meta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/organisations/index.vue?macro=true";
import { default as _91token_93LKYQIuAqAIMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/password-reset/[token].vue?macro=true";
import { default as indexlj1bE06edKMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/password-reset/index.vue?macro=true";
import { default as plan_45du_45siteE39kcoTeKUMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/plan-du-site.vue?macro=true";
import { default as politique_45de_45confidentialitel2ODxd91irMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/politique-de-confidentialite.vue?macro=true";
import { default as indexMBdnFWiUKfMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/profile/alertes/index.vue?macro=true";
import { default as charte_45bon_45fonctionnementpX073fzjWmMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/profile/charte-bon-fonctionnement.vue?macro=true";
import { default as editIL3ZuQManCMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/profile/edit.vue?macro=true";
import { default as favorisIIYzOZvJPsMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/profile/favoris.vue?macro=true";
import { default as indexOWvH2gybC3Meta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/profile/index.vue?macro=true";
import { default as missionssZR0k5eveWMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/profile/missions.vue?macro=true";
import { default as notificationsIEARNZihMcMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/profile/notifications.vue?macro=true";
import { default as preferencesIHJO3ZSM39Meta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/profile/preferences.vue?macro=true";
import { default as settingsLtpqwqqMgrMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/profile/settings.vue?macro=true";
import { default as decembre_45ensembleWTZ31fW2riMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/quiz/decembre-ensemble.vue?macro=true";
import { default as generiqueZS25wzjukMMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/quiz/generique.vue?macro=true";
import { default as mgen4Z7o49LkZxMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/quiz/mgen.vue?macro=true";
import { default as printemps_45pour_45la_45planetec1rtB4NUupMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/quiz/printemps-pour-la-planete.vue?macro=true";
import { default as septembre_45pour_45apprendreAvU2jcyR39Meta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/quiz/septembre-pour-apprendre.vue?macro=true";
import { default as _91slug_93IW3JDfjaUeMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/reseaux/[slug].vue?macro=true";
import { default as indextEySLk5aEmMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/stats/index.vue?macro=true";
import { default as missionswQoP0DOTI1Meta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/stats/missions.vue?macro=true";
import { default as organisations2JPm0owiSQMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/stats/organisations.vue?macro=true";
import { default as participationsR1QbCJJDRJMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/stats/participations.vue?macro=true";
import { default as places3CwT7r7rFCMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/stats/places.vue?macro=true";
import { default as utilisateursHZRvLLoSzNMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/stats/utilisateurs.vue?macro=true";
import { default as search_45archived_45usersP6MXWq6QViMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/support/actions/search-archived-users.vue?macro=true";
import { default as transfert_45organisation5QRFtGsOn7Meta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/support/actions/transfert-organisation.vue?macro=true";
import { default as user_45reset_45context_45role5Kysn8tcbfMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/support/actions/user-reset-context-role.vue?macro=true";
import { default as user_45reset_45password_45linkE2ec2MpEodMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/support/actions/user-reset-password-link.vue?macro=true";
import { default as doublons_45organisationshLoQtfYuHFMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/support/contents/doublons-organisations.vue?macro=true";
import { default as doublons_45territoiresef7Ob0XzV6Meta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/support/contents/doublons-territoires.vue?macro=true";
import { default as indexAYXgTYpztUMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/support/index.vue?macro=true";
import { default as activity_45logsTDT8UGENWEMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/support/referents/activity-logs.vue?macro=true";
import { default as waiting_45actionsRxemhG59VdMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/support/referents/waiting-actions.vue?macro=true";
import { default as missions_45outdatedNijwquUOSwMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/support/responsables/missions-outdated.vue?macro=true";
import { default as participations_45to_45be_45treatedUBo4VNawewMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/support/responsables/participations-to-be-treated.vue?macro=true";
import { default as _91token_93I2lea2JIVFMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/temoignages/[token].vue?macro=true";
import { default as indexRr20Ryv6LxMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/territoires/index.vue?macro=true";
import { default as _91slug_93yXdhwDFYTKMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/villes/[slug].vue?macro=true";
import { default as component_45stub3a0LyRiWHAMeta } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stub3a0LyRiWHA } from "/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "accessibilite",
    path: "/accessibilite",
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/accessibilite.vue").then(m => m.default || m)
  },
  {
    name: "activites-slug",
    path: "/activites/:slug()",
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/activites/[slug].vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-activites-id-edit",
    path: "/admin/contenus/activites/:id()/edit",
    meta: editdUqbTwebM7Meta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/contenus/activites/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-activites-id",
    path: "/admin/contenus/activites/:id()",
    meta: indexFo0oXpEzRjMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/contenus/activites/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-activites-add",
    path: "/admin/contenus/activites/add",
    meta: addXI1IZd7CGpMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/contenus/activites/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-activites",
    path: "/admin/contenus/activites",
    meta: indexj2jXCiCPybMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/contenus/activites/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-domaines-id-edit",
    path: "/admin/contenus/domaines/:id()/edit",
    meta: editYIJpxyENBBMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/contenus/domaines/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-domaines-add",
    path: "/admin/contenus/domaines/add",
    meta: addmzI0uK9tNRMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/contenus/domaines/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-domaines",
    path: "/admin/contenus/domaines",
    meta: index0MdTELNN0yMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/contenus/domaines/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-modeles-mission-id-edit",
    path: "/admin/contenus/modeles-mission/:id()/edit",
    meta: editpjWiOlNS3RMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/contenus/modeles-mission/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-modeles-mission-add",
    path: "/admin/contenus/modeles-mission/add",
    meta: addg4NzeR3vgjMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/contenus/modeles-mission/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-modeles-mission",
    path: "/admin/contenus/modeles-mission",
    meta: indexIjnO5TGwyWMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/contenus/modeles-mission/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-posts",
    path: "/admin/contenus/posts",
    meta: postsuaf6WDt8VOMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/contenus/posts.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-reseaux-id-edit",
    path: "/admin/contenus/reseaux/:id()/edit",
    meta: edityftKah94CaMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/contenus/reseaux/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-reseaux-id",
    path: "/admin/contenus/reseaux/:id()",
    meta: indexcpMHsFDLO0Meta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/contenus/reseaux/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-reseaux-add",
    path: "/admin/contenus/reseaux/add",
    meta: add9A10lmvnNCMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/contenus/reseaux/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-reseaux",
    path: "/admin/contenus/reseaux",
    meta: indexZGngJY1ru0Meta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/contenus/reseaux/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-ressources-id-edit",
    path: "/admin/contenus/ressources/:id()/edit",
    meta: editN95l5gh3PaMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/contenus/ressources/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-ressources-add",
    path: "/admin/contenus/ressources/add",
    meta: addd8JPRusFUJMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/contenus/ressources/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-ressources",
    path: "/admin/contenus/ressources",
    meta: indexWg4rmF9RHGMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/contenus/ressources/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-territoires-id-edit",
    path: "/admin/contenus/territoires/:id()/edit",
    meta: edit2Oi2sPidGDMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/contenus/territoires/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-territoires-id",
    path: "/admin/contenus/territoires/:id()",
    meta: indexJZ7XVs7kHyMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/contenus/territoires/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-territoires-add",
    path: "/admin/contenus/territoires/add",
    meta: addjeq9ToOfxTMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/contenus/territoires/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-territoires",
    path: "/admin/contenus/territoires",
    meta: indext55XrfPjUvMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/contenus/territoires/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-testimonials-id-edit",
    path: "/admin/contenus/testimonials/:id()/edit",
    meta: editjMmVumNsqjMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/contenus/testimonials/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-testimonials",
    path: "/admin/contenus/testimonials",
    meta: indexNCyKQ1VdHcMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/contenus/testimonials/index.vue").then(m => m.default || m)
  },
  {
    name: "admin",
    path: "/admin",
    meta: indexOARPo31EkbMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions-id-benevoles-informations",
    path: "/admin/missions/:id()/benevoles-informations",
    meta: benevoles_45informationsmp79lbTf6zMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/missions/[id]/benevoles-informations.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions-id-benevoles",
    path: "/admin/missions/:id()/benevoles",
    meta: benevoleswwkBeRLVPSMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/missions/[id]/benevoles.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions-id-dates",
    path: "/admin/missions/:id()/dates",
    meta: datesjb7WvJvVg1Meta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/missions/[id]/dates.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions-id",
    path: "/admin/missions/:id()",
    meta: indexgVex4zq4qoMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/missions/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions-id-informations",
    path: "/admin/missions/:id()/informations",
    meta: informationsjIYe1XCOQVMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/missions/[id]/informations.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions-id-lieux",
    path: "/admin/missions/:id()/lieux",
    meta: lieuxy8oDyVt6cEMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/missions/[id]/lieux.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions-id-responsables",
    path: "/admin/missions/:id()/responsables",
    meta: responsables7IlVyA1RD9Meta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/missions/[id]/responsables.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions-id-title",
    path: "/admin/missions/:id()/title",
    meta: title9r4p0kii6SMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/missions/[id]/title.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions-id-trouver-des-benevoles",
    path: "/admin/missions/:id()/trouver-des-benevoles",
    meta: trouver_45des_45benevoles3dFUGbZsCcMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/missions/[id]/trouver-des-benevoles.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions-id-visuel",
    path: "/admin/missions/:id()/visuel",
    meta: visuelnShcivFjboMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/missions/[id]/visuel.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions",
    path: "/admin/missions",
    meta: indexAYyaUMjfAfMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/missions/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-notes",
    path: "/admin/notes",
    meta: notesX4bn1TqcrBMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/notes.vue").then(m => m.default || m)
  },
  {
    name: "admin-organisations-id-edit",
    path: "/admin/organisations/:id()/edit",
    meta: edit0NXocZgtxgMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/organisations/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-organisations-id",
    path: "/admin/organisations/:id()",
    meta: indexR14TWXGQlhMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/organisations/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-organisations-id-missions-add",
    path: "/admin/organisations/:id()/missions/add",
    meta: addJkAlCb8NeBMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/organisations/[id]/missions/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-organisations",
    path: "/admin/organisations",
    meta: indexVWyGXj1vcLMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/organisations/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-other-invitations",
    path: "/admin/other/invitations",
    meta: invitationsIGHuTfsL8KMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/other/invitations.vue").then(m => m.default || m)
  },
  {
    name: "admin-other-logs",
    path: "/admin/other/logs",
    meta: logsU38qtM0WMMMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/other/logs.vue").then(m => m.default || m)
  },
  {
    name: "admin-participations",
    path: "/admin/participations",
    meta: indexXqZrIseBWBMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/participations/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-ressources",
    path: "/admin/ressources",
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/ressources.vue").then(m => m.default || m)
  },
  {
    name: "admin-settings-emails",
    path: "/admin/settings/emails",
    meta: emailsTIlFSVvEpwMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/settings/emails.vue").then(m => m.default || m)
  },
  {
    name: "admin-settings-messages",
    path: "/admin/settings/messages",
    meta: messagesVnCqOEXbJSMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/settings/messages.vue").then(m => m.default || m)
  },
  {
    name: "admin-settings-rules-id-edit",
    path: "/admin/settings/rules/:id()/edit",
    meta: editxWObBvY7jZMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/settings/rules/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-settings-rules-id-pending-items",
    path: "/admin/settings/rules/:id()/pending-items",
    meta: pending_45itemspzj4aPLzBcMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/settings/rules/[id]/pending-items.vue").then(m => m.default || m)
  },
  {
    name: "admin-settings-rules-add",
    path: "/admin/settings/rules/add",
    meta: addhSiHdKO4ZjMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/settings/rules/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-settings-rules",
    path: "/admin/settings/rules",
    meta: indexy8ru9NAPjiMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/settings/rules/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-api-engagement-trafic-entrant",
    path: "/admin/statistics/api-engagement/trafic-entrant",
    meta: trafic_45entrantwnxnFcugagMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/statistics/api-engagement/trafic-entrant.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-api-engagement-trafic-sortant",
    path: "/admin/statistics/api-engagement/trafic-sortant",
    meta: trafic_45sortantsabzBXUP8aMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/statistics/api-engagement/trafic-sortant.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-conversions",
    path: "/admin/statistics/conversions",
    meta: conversionsOQijExjPmPMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/statistics/conversions.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-departements-missions-a-valider",
    path: "/admin/statistics/departements/missions-a-valider",
    meta: missions_45a_45validera4j2K4dDWZMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/statistics/departements/missions-a-valider.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-departements-missions-perimees",
    path: "/admin/statistics/departements/missions-perimees",
    meta: missions_45perimeesKmDb3iXrUbMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/statistics/departements/missions-perimees.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-departements-organisations-a-valider",
    path: "/admin/statistics/departements/organisations-a-valider",
    meta: organisations_45a_45validervMJlZt9LtyMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/statistics/departements/organisations-a-valider.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics",
    path: "/admin/statistics",
    meta: indexjVICKTVQCvMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/statistics/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-indicateurs-cles",
    path: "/admin/statistics/indicateurs-cles",
    meta: indicateurs_45clesBK5XSS90O7Meta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/statistics/indicateurs-cles.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-missions",
    path: "/admin/statistics/missions",
    meta: missionszYlYkTizmRMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/statistics/missions.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-organisations",
    path: "/admin/statistics/organisations",
    meta: indexAvfl5wyr3eMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/statistics/organisations/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-organisations-missions-perimees",
    path: "/admin/statistics/organisations/missions-perimees",
    meta: missions_45perimeesmstDB2O3GxMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/statistics/organisations/missions-perimees.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-organisations-participations-a-valider",
    path: "/admin/statistics/organisations/participations-a-valider",
    meta: participations_45a_45validerrMgH5xvKNQMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/statistics/organisations/participations-a-valider.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-organisations-participations-refusees-annulees",
    path: "/admin/statistics/organisations/participations-refusees-annulees",
    meta: participations_45refusees_45annuleesDEbGFImy3xMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/statistics/organisations/participations-refusees-annulees.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-participations",
    path: "/admin/statistics/participations",
    meta: participations9JTh4EY8CeMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/statistics/participations.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-places",
    path: "/admin/statistics/places",
    meta: placesaeArWjmSAGMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/statistics/places.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-suivis-moderations",
    path: "/admin/statistics/suivis/moderations",
    meta: moderationsavF04747TuMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/statistics/suivis/moderations.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-utilisateurs",
    path: "/admin/statistics/utilisateurs",
    meta: utilisateurs5Ug1uzEgBXMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/statistics/utilisateurs.vue").then(m => m.default || m)
  },
  {
    name: "admin-taxonomies-slug-id-edit",
    path: "/admin/taxonomies/:slug()/:id()/edit",
    meta: edit0cRrT8vKoyMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/taxonomies/[slug]/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-taxonomies-slug-add",
    path: "/admin/taxonomies/:slug()/add",
    meta: addwGfFowiQRvMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/taxonomies/[slug]/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-taxonomies-slug",
    path: "/admin/taxonomies/:slug()",
    meta: indexEz5I6a1yBKMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/taxonomies/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-temoignages",
    path: "/admin/temoignages",
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/temoignages.vue").then(m => m.default || m)
  },
  {
    name: "admin-utilisateurs-id-edit",
    path: "/admin/utilisateurs/:id()/edit",
    meta: editEEWTeE7Jm7Meta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/utilisateurs/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-utilisateurs-id",
    path: "/admin/utilisateurs/:id()",
    meta: indexkTMGcuyJsmMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/utilisateurs/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-utilisateurs",
    path: "/admin/utilisateurs",
    meta: indexeEyA5b66YLMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/admin/utilisateurs/index.vue").then(m => m.default || m)
  },
  {
    name: "charte-bon-fonctionnement-benevoles",
    path: "/charte-bon-fonctionnement/benevoles",
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/charte-bon-fonctionnement/benevoles.vue").then(m => m.default || m)
  },
  {
    name: "charte-bon-fonctionnement",
    path: "/charte-bon-fonctionnement",
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/charte-bon-fonctionnement/index.vue").then(m => m.default || m)
  },
  {
    name: "charte-bon-fonctionnement-responsables",
    path: "/charte-bon-fonctionnement/responsables",
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/charte-bon-fonctionnement/responsables.vue").then(m => m.default || m)
  },
  {
    name: "charte-reserve-civique",
    path: "/charte-reserve-civique",
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/charte-reserve-civique.vue").then(m => m.default || m)
  },
  {
    name: "conditions-generales-d-utilisation",
    path: "/conditions-generales-d-utilisation",
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/conditions-generales-d-utilisation.vue").then(m => m.default || m)
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: indexqdTcg4klblMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "departements-slug",
    path: "/departements/:slug()",
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/departements/[slug].vue").then(m => m.default || m)
  },
  {
    name: "domaines-action-slug",
    path: "/domaines-action/:slug()",
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/domaines-action/[slug].vue").then(m => m.default || m)
  },
  {
    name: "en-ce-moment",
    path: "/en-ce-moment",
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/en-ce-moment.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "inscription-benevole",
    path: "/inscription/benevole",
    meta: indexlEkuSNcupyMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/inscription/benevole/index.vue").then(m => m.default || m)
  },
  {
    name: "inscription-benevole-step-competences",
    path: "/inscription/benevole/step/competences",
    meta: competencesgVJh930GW1Meta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/inscription/benevole/step/competences.vue").then(m => m.default || m)
  },
  {
    name: "inscription-benevole-step-disponibilites",
    path: "/inscription/benevole/step/disponibilites",
    meta: disponibilitescYqneXplOaMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/inscription/benevole/step/disponibilites.vue").then(m => m.default || m)
  },
  {
    name: "inscription-benevole-step-preferences",
    path: "/inscription/benevole/step/preferences",
    meta: preferences7pwzDDqAGvMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/inscription/benevole/step/preferences.vue").then(m => m.default || m)
  },
  {
    name: "inscription-benevole-step-profile",
    path: "/inscription/benevole/step/profile",
    meta: profilesV8jRRYoiDMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/inscription/benevole/step/profile.vue").then(m => m.default || m)
  },
  {
    name: "inscription",
    path: "/inscription",
    meta: index6TNqFOkSXMMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/inscription/index.vue").then(m => m.default || m)
  },
  {
    name: "inscription-responsable",
    path: "/inscription/responsable",
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/inscription/responsable/index.vue").then(m => m.default || m)
  },
  {
    name: "inscription-responsable-step-charte-bon-fonctionnement",
    path: "/inscription/responsable/step/charte-bon-fonctionnement",
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/inscription/responsable/step/charte-bon-fonctionnement.vue").then(m => m.default || m)
  },
  {
    name: "inscription-responsable-step-collectivite-confirmation",
    path: "/inscription/responsable/step/collectivite-confirmation",
    meta: collectivite_45confirmationPxOM6VUAtOMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/inscription/responsable/step/collectivite-confirmation.vue").then(m => m.default || m)
  },
  {
    name: "inscription-responsable-step-collectivite",
    path: "/inscription/responsable/step/collectivite",
    meta: collectiviteDx0ZCaD77kMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/inscription/responsable/step/collectivite.vue").then(m => m.default || m)
  },
  {
    name: "inscription-responsable-step-organisation-confirmation",
    path: "/inscription/responsable/step/organisation-confirmation",
    meta: organisation_45confirmationYgenJxAlG2Meta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/inscription/responsable/step/organisation-confirmation.vue").then(m => m.default || m)
  },
  {
    name: "inscription-responsable-step-organisation-details",
    path: "/inscription/responsable/step/organisation-details",
    meta: organisation_45details65IZuCc0H0Meta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/inscription/responsable/step/organisation-details.vue").then(m => m.default || m)
  },
  {
    name: "inscription-responsable-step-organisation-images",
    path: "/inscription/responsable/step/organisation-images",
    meta: organisation_45images1gCbx4SmAOMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/inscription/responsable/step/organisation-images.vue").then(m => m.default || m)
  },
  {
    name: "inscription-responsable-step-organisation",
    path: "/inscription/responsable/step/organisation",
    meta: organisationZe0wpyGxefMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/inscription/responsable/step/organisation.vue").then(m => m.default || m)
  },
  {
    name: "inscription-responsable-step-profile",
    path: "/inscription/responsable/step/profile",
    meta: profileSWF2kiBtTMMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/inscription/responsable/step/profile.vue").then(m => m.default || m)
  },
  {
    name: "invitations-hash",
    path: "/invitations/:hash()",
    meta: _91hash_93kL2u7q4DtJMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/invitations/[hash].vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginwbfs2Pdm9nMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "maintenance-admin",
    path: "/maintenance/admin",
    meta: admintaUOxiDNmcMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/maintenance/admin.vue").then(m => m.default || m)
  },
  {
    name: "maintenance",
    path: "/maintenance",
    meta: index4HL3FTpFJyMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/maintenance/index.vue").then(m => m.default || m)
  },
  {
    name: "mentions-legales",
    path: "/mentions-legales",
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/mentions-legales.vue").then(m => m.default || m)
  },
  {
    name: "messages-id",
    path: "/messages/:id()",
    meta: _91id_93vKIe5pA57PMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/messages/[id].vue").then(m => m.default || m)
  },
  {
    name: "messages",
    path: "/messages",
    meta: indexAD1IwiyI22Meta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/messages/index.vue").then(m => m.default || m)
  },
  {
    name: "messages-modeles-add",
    path: "/messages/modeles/add",
    meta: addWn6VSjOHt2Meta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/messages/modeles/add.vue").then(m => m.default || m)
  },
  {
    name: "messages-modeles",
    path: "/messages/modeles",
    meta: indexMXPvuVVi1QMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/messages/modeles/index.vue").then(m => m.default || m)
  },
  {
    name: "missions-benevolat-id-slug",
    path: "/missions-benevolat/:id()/:slug()",
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/missions-benevolat/[id]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "missions-benevolat-id",
    path: "/missions-benevolat/:id()",
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/missions-benevolat/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "missions-benevolat",
    path: "/missions-benevolat",
    meta: indexHl9aG2fXgBMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/missions-benevolat/index.vue").then(m => m.default || m)
  },
  {
    name: "notifications",
    path: "/notifications",
    meta: notificationsw0T8Sinz7NMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/notifications.vue").then(m => m.default || m)
  },
  {
    name: "organisations-slug",
    path: "/organisations/:slug()",
    meta: _91slug_93c5vvjP75TqMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/organisations/[slug].vue").then(m => m.default || m)
  },
  {
    name: "organisations",
    path: "/organisations",
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/organisations/index.vue").then(m => m.default || m)
  },
  {
    name: "password-reset-token",
    path: "/password-reset/:token()",
    meta: _91token_93LKYQIuAqAIMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/password-reset/[token].vue").then(m => m.default || m)
  },
  {
    name: "password-reset",
    path: "/password-reset",
    meta: indexlj1bE06edKMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/password-reset/index.vue").then(m => m.default || m)
  },
  {
    name: "plan-du-site",
    path: "/plan-du-site",
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/plan-du-site.vue").then(m => m.default || m)
  },
  {
    name: "politique-de-confidentialite",
    path: "/politique-de-confidentialite",
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/politique-de-confidentialite.vue").then(m => m.default || m)
  },
  {
    name: "profile-alertes",
    path: "/profile/alertes",
    meta: indexMBdnFWiUKfMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/profile/alertes/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-charte-bon-fonctionnement",
    path: "/profile/charte-bon-fonctionnement",
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/profile/charte-bon-fonctionnement.vue").then(m => m.default || m)
  },
  {
    name: "profile-edit",
    path: "/profile/edit",
    meta: editIL3ZuQManCMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/profile/edit.vue").then(m => m.default || m)
  },
  {
    name: "profile-favoris",
    path: "/profile/favoris",
    meta: favorisIIYzOZvJPsMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/profile/favoris.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    meta: indexOWvH2gybC3Meta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-missions",
    path: "/profile/missions",
    meta: missionssZR0k5eveWMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/profile/missions.vue").then(m => m.default || m)
  },
  {
    name: "profile-notifications",
    path: "/profile/notifications",
    meta: notificationsIEARNZihMcMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/profile/notifications.vue").then(m => m.default || m)
  },
  {
    name: "profile-preferences",
    path: "/profile/preferences",
    meta: preferencesIHJO3ZSM39Meta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/profile/preferences.vue").then(m => m.default || m)
  },
  {
    name: "profile-settings",
    path: "/profile/settings",
    meta: settingsLtpqwqqMgrMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/profile/settings.vue").then(m => m.default || m)
  },
  {
    name: "quiz-decembre-ensemble",
    path: "/quiz/decembre-ensemble",
    meta: decembre_45ensembleWTZ31fW2riMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/quiz/decembre-ensemble.vue").then(m => m.default || m)
  },
  {
    name: "quiz-generique",
    path: "/quiz/generique",
    meta: generiqueZS25wzjukMMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/quiz/generique.vue").then(m => m.default || m)
  },
  {
    name: "quiz-mgen",
    path: "/quiz/mgen",
    meta: mgen4Z7o49LkZxMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/quiz/mgen.vue").then(m => m.default || m)
  },
  {
    name: "quiz-printemps-pour-la-planete",
    path: "/quiz/printemps-pour-la-planete",
    meta: printemps_45pour_45la_45planetec1rtB4NUupMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/quiz/printemps-pour-la-planete.vue").then(m => m.default || m)
  },
  {
    name: "quiz-septembre-pour-apprendre",
    path: "/quiz/septembre-pour-apprendre",
    meta: septembre_45pour_45apprendreAvU2jcyR39Meta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/quiz/septembre-pour-apprendre.vue").then(m => m.default || m)
  },
  {
    name: "reseaux-slug",
    path: "/reseaux/:slug()",
    meta: _91slug_93IW3JDfjaUeMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/reseaux/[slug].vue").then(m => m.default || m)
  },
  {
    name: "stats",
    path: "/stats",
    meta: indextEySLk5aEmMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/stats/index.vue").then(m => m.default || m)
  },
  {
    name: "stats-missions",
    path: "/stats/missions",
    meta: missionswQoP0DOTI1Meta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/stats/missions.vue").then(m => m.default || m)
  },
  {
    name: "stats-organisations",
    path: "/stats/organisations",
    meta: organisations2JPm0owiSQMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/stats/organisations.vue").then(m => m.default || m)
  },
  {
    name: "stats-participations",
    path: "/stats/participations",
    meta: participationsR1QbCJJDRJMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/stats/participations.vue").then(m => m.default || m)
  },
  {
    name: "stats-places",
    path: "/stats/places",
    meta: places3CwT7r7rFCMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/stats/places.vue").then(m => m.default || m)
  },
  {
    name: "stats-utilisateurs",
    path: "/stats/utilisateurs",
    meta: utilisateursHZRvLLoSzNMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/stats/utilisateurs.vue").then(m => m.default || m)
  },
  {
    name: "support-actions-search-archived-users",
    path: "/support/actions/search-archived-users",
    meta: search_45archived_45usersP6MXWq6QViMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/support/actions/search-archived-users.vue").then(m => m.default || m)
  },
  {
    name: "support-actions-transfert-organisation",
    path: "/support/actions/transfert-organisation",
    meta: transfert_45organisation5QRFtGsOn7Meta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/support/actions/transfert-organisation.vue").then(m => m.default || m)
  },
  {
    name: "support-actions-user-reset-context-role",
    path: "/support/actions/user-reset-context-role",
    meta: user_45reset_45context_45role5Kysn8tcbfMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/support/actions/user-reset-context-role.vue").then(m => m.default || m)
  },
  {
    name: "support-actions-user-reset-password-link",
    path: "/support/actions/user-reset-password-link",
    meta: user_45reset_45password_45linkE2ec2MpEodMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/support/actions/user-reset-password-link.vue").then(m => m.default || m)
  },
  {
    name: "support-contents-doublons-organisations",
    path: "/support/contents/doublons-organisations",
    meta: doublons_45organisationshLoQtfYuHFMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/support/contents/doublons-organisations.vue").then(m => m.default || m)
  },
  {
    name: "support-contents-doublons-territoires",
    path: "/support/contents/doublons-territoires",
    meta: doublons_45territoiresef7Ob0XzV6Meta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/support/contents/doublons-territoires.vue").then(m => m.default || m)
  },
  {
    name: "support",
    path: "/support",
    meta: indexAYXgTYpztUMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: "support-referents-activity-logs",
    path: "/support/referents/activity-logs",
    meta: activity_45logsTDT8UGENWEMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/support/referents/activity-logs.vue").then(m => m.default || m)
  },
  {
    name: "support-referents-waiting-actions",
    path: "/support/referents/waiting-actions",
    meta: waiting_45actionsRxemhG59VdMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/support/referents/waiting-actions.vue").then(m => m.default || m)
  },
  {
    name: "support-responsables-missions-outdated",
    path: "/support/responsables/missions-outdated",
    meta: missions_45outdatedNijwquUOSwMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/support/responsables/missions-outdated.vue").then(m => m.default || m)
  },
  {
    name: "support-responsables-participations-to-be-treated",
    path: "/support/responsables/participations-to-be-treated",
    meta: participations_45to_45be_45treatedUBo4VNawewMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/support/responsables/participations-to-be-treated.vue").then(m => m.default || m)
  },
  {
    name: "temoignages-token",
    path: "/temoignages/:token()",
    meta: _91token_93I2lea2JIVFMeta || {},
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/temoignages/[token].vue").then(m => m.default || m)
  },
  {
    name: "territoires",
    path: "/territoires",
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/territoires/index.vue").then(m => m.default || m)
  },
  {
    name: "villes-slug",
    path: "/villes/:slug()",
    component: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/pages/villes/[slug].vue").then(m => m.default || m)
  },
  {
    name: component_45stub3a0LyRiWHAMeta?.name,
    path: "/register/volontaire/:pathMatch(.*)",
    component: component_45stub3a0LyRiWHA
  },
  {
    name: component_45stub3a0LyRiWHAMeta?.name,
    path: "/register/responsable/:pathMatch(.*)",
    component: component_45stub3a0LyRiWHA
  },
  {
    name: component_45stub3a0LyRiWHAMeta?.name,
    path: "/user/settings",
    component: component_45stub3a0LyRiWHA
  },
  {
    name: component_45stub3a0LyRiWHAMeta?.name,
    path: "/user/:pathMatch(.*)",
    component: component_45stub3a0LyRiWHA
  },
  {
    name: component_45stub3a0LyRiWHAMeta?.name,
    path: "/dashboard/structure/:pathMatch(.*)",
    component: component_45stub3a0LyRiWHA
  },
  {
    name: component_45stub3a0LyRiWHAMeta?.name,
    path: "/dashboard/mission/:pathMatch(.*)",
    component: component_45stub3a0LyRiWHA
  },
  {
    name: component_45stub3a0LyRiWHAMeta?.name,
    path: "/dashboard/participation/:pathMatch(.*)",
    component: component_45stub3a0LyRiWHA
  },
  {
    name: component_45stub3a0LyRiWHAMeta?.name,
    path: "/dashboard/profile/:pathMatch(.*)",
    component: component_45stub3a0LyRiWHA
  },
  {
    name: component_45stub3a0LyRiWHAMeta?.name,
    path: "/dashboard/reseaux/:pathMatch(.*)",
    component: component_45stub3a0LyRiWHA
  },
  {
    name: component_45stub3a0LyRiWHAMeta?.name,
    path: "/inscription/organisation",
    component: component_45stub3a0LyRiWHA
  },
  {
    name: component_45stub3a0LyRiWHAMeta?.name,
    path: "/statistiques",
    component: component_45stub3a0LyRiWHA
  },
  {
    name: component_45stub3a0LyRiWHAMeta?.name,
    path: "/activites/accompagnement-aux-activites-sportives",
    component: component_45stub3a0LyRiWHA
  },
  {
    name: component_45stub3a0LyRiWHAMeta?.name,
    path: "/sitemap.xml",
    component: component_45stub3a0LyRiWHA
  }
]