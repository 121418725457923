export default {
  account: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/layouts/account.vue").then(m => m.default || m),
  "admin-with-sidebar-menu": () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/layouts/admin-with-sidebar-menu.vue").then(m => m.default || m),
  "default-without-header": () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/layouts/default-without-header.vue").then(m => m.default || m),
  default: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/layouts/default.vue").then(m => m.default || m),
  empty: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/layouts/empty.vue").then(m => m.default || m),
  "form-mission": () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/layouts/form-mission.vue").then(m => m.default || m),
  "messages-params": () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/layouts/messages-params.vue").then(m => m.default || m),
  messages: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/layouts/messages.vue").then(m => m.default || m),
  quiz: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/layouts/quiz.vue").then(m => m.default || m),
  "register-steps": () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/layouts/register-steps.vue").then(m => m.default || m),
  "statistics-admin": () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/layouts/statistics-admin.vue").then(m => m.default || m),
  "statistics-public": () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/layouts/statistics-public.vue").then(m => m.default || m),
  support: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/layouts/support.vue").then(m => m.default || m),
  temoignage: () => import("/build/8c44077c-64dc-4f5d-86f4-d2f25f7a8559/layouts/temoignage.vue").then(m => m.default || m)
}